import React from 'react';
import { SeoMetadataFragment } from 'graphql-types';
import { Helmet } from 'react-helmet';

export const SeoMetadata: React.FunctionComponent<{
  metaData: SeoMetadataFragment;
  slug: string;
}> = ({ metaData, slug }) => {
  const canonicalUrl =
    metaData.canonicalUrl || `${process.env.GATSBY_HOME_URL || ''}/${slug}/`;
  return (
    <Helmet defer={false}>
      <title>{metaData.pageTitle}</title>
      {metaData.metaDescription && (
        <meta name="description" content={metaData.metaDescription}></meta>
      )}
      {metaData.metaKeywords && (
        <meta name="keywords" content={metaData.metaKeywords}></meta>
      )}
      {!metaData.allowRobotIndexing && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {metaData.openGraphTitle && (
        <meta property="og:title" content={metaData.openGraphTitle} />
      )}
      {metaData.openGraphDescription && (
        <meta
          property="og:description"
          content={metaData.openGraphDescription}
        />
      )}
      {metaData.openGraphImage?.image?.asset?.url && (
        <meta
          property="og:image"
          content={metaData.openGraphImage.image.asset.url}
        />
      )}
      {metaData.openGraphImage?.alt && (
        <meta property="og:image:alt" content={metaData.openGraphImage.alt} />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};
