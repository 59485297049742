import { getToken } from 'helpers/Customer';
import { useEffect } from 'react';
import { currentSite } from '../utils/site';
import { getIcid, getSourceCode } from '@bbnb/openfit-frontend-shared';

let TRYOUTS_EXCEEDED = false;
const DEBUG = false;
const TIMEOUT = 100;
const MAX_TRYOUTS = 10;

type TealiumData = Record<string, any>;

const tagTimeout = (
  data: TealiumData,
  method: string,
  num: number,
  cb: (result: boolean) => any
) => {
  DEBUG && console.log('[Tealium] Tryout:', num);

  if (num >= MAX_TRYOUTS || TRYOUTS_EXCEEDED) {
    DEBUG && console.log('[Tealium] Max tryout reached!');
    TRYOUTS_EXCEEDED = true;
    return cb(false);
  }

  if (!window.utag) {
    return setTimeout(
      tagTimeout.bind(null, data, method, num + 1, cb),
      TIMEOUT
    );
  }

  DEBUG && console.log('[Tealium] Tryout successful:', num);
  window.utag[method](data, () => cb(true));

  return true;
};

const updateUtagData = () => {
  try {
    window.utag && window.utag.loader.RDdom(window.utag.data);
  } catch (e) {
    console.error('[Tealium] Update utag.data failed:', e);
  }
};

const getPageName = () =>
  `sell-${
    window.location.pathname.split('/').filter(Boolean).slice(-1)[0] || 'home'
  }`;

const getGeneralFields = () => {
  return {
    page_type: 'product', //Suggested
    country_code: 'US', //Suggested
    icid: getIcid(), //Suggested
    language_code: 'en', //Suggested
    page_name: `SHOP/${getPageName()}`, //Required
    site_section: 'SHOP', //Required
    source_code: getSourceCode(), //Suggested
  };
};

const getCustomerId = () => {
  const token = getToken();

  if (token === null) {
    return {
      customer_account_id: 'N/A',
      customer_profile_id: 'N/A',
      email: 'N/A',
    };
  }

  return {
    customer_account_id: token.sub, //Required
    customer_profile_id: `${token?.sub}-1`, //Required
    email: token?.email || 'N/A',
  };
};

const tag = (data: TealiumData, method: string) => {
  // updating utag.data to have latest pagename info
  updateUtagData();

  const tealiumData = {
    site: currentSite(),
    ...data,
    ...getCustomerId(),
  };

  return new Promise((resolve) => {
    DEBUG && console.log('[Tealium] Data:', tealiumData);
    tagTimeout(tealiumData, method, 1, (result) => {
      DEBUG && console.log('[Tealium] Result:', result);
      setTimeout(() => resolve(result), 200);
    });
  });
};

const tagView = (data: TealiumData) => {
  return tag(data, 'view');
};

const tagLink = (data: TealiumData) => tag(data, 'link');

export const tagStandardPageView = (
  eventName: string,
  eventProperties: TealiumData = {}
) => {
  try {
    return tagView({
      ...getGeneralFields(),
      ...getCustomerId(),
      ...eventProperties,
      tealium_event: eventName, //Required
    });
  } catch (e) {
    console.error('[Tealium] Error:', e);
  }
  return false;
};

export const useTagStandardPageView = (
  eventName: string,
  eventProperties?: TealiumData
) => {
  useEffect(() => {
    tagStandardPageView(eventName, eventProperties);
    // Dependency list was intentionally left blank - We don't want tags firing multiple times for stuff like flavor changes.
  }, []);
};

export const tagSellPageView = (data: TealiumData = {}) =>
  tagStandardPageView('sell_page_view', { event_data: data });

export const tagLinkClick = (eventName = 'N/A', data: TealiumData = {}) => {
  try {
    return tagLink({
      ...getGeneralFields(),
      ...getCustomerId(),
      ...data,
      tealium_event: eventName,
    });
  } catch (e) {
    console.error('[Tealium] Error:', e);
  }
  return Promise.resolve();
};

export const tagCartAdd = (
  products: {
    id?: string;
    name?: string;
    price?: number;
    sku?: string;
    currencyCode?: string;
  }[]
) => {
  return tagLinkClick('cart_add', {
    cart_product_id: products.map((p) => p.id),
    cart_product_variant_id: products.map((p) => p.sku),
    cart_product_variant_price: products.map((p) => p.price),
    cart_product_variant_currency_code: products.map(
      (p) => p.currencyCode || 'USD'
    ),
    cart_product_variant_quantity: products.map((p) => 1),
    cart_product_variant_sku: products.map((p) => p.sku),
    cart_total_items: products.length,
    cart_total_value: products.reduce(
      (sum, p): number => sum + (p.price || 0),
      0
    ),
    product_name: products.map((p) => p.name),
    product_variant_id: products.map((p) => p.sku),
    product_variant_price: products.map((p) => p.price),
    product_variant_currency_code: products.map((p) => p.currencyCode || 'USD'),
  });
};
