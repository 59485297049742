import { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImageDataArgs, getGatsbyImageData } from 'gatsby-source-sanity';
import { SanityImage } from 'graphql-types';
import { config, getSimplifiedImageData } from './SanityMapper';

/**
 * In the sanity image creation process we can not use the images from different sources we need to create a image that lives within
 * our sanity image data store.
 *
 * @param id It is a combination of the images properties from which sanity image soruce will create all the images example. 'image-{imageId}-{sizes}-{type}'
 * @returns
 */
export const createAssetFromId = (
  id: string,
  args: GatsbyImageDataArgs = {}
): SanityImage => {
  const _id = id.startsWith('image-') ? id : `image-${id}`;
  return {
    asset: {
      _id,
      gatsbyImageData: getGatsbyImageData(_id, args, config),
    },
  } as SanityImage;
};

export const createAssetFromIdWithPlaceholder = (
  id: string,
  args: GatsbyImageDataArgs = {}
) => {
  const _id = id.startsWith('image-') ? id : `image-${id}`;
  return {
    asset: {
      _id,
      gatsbyImageData: getGatsbyImageData(_id, args, config),
      placeholder: {
        _id,
        gatsbyImageData: getGatsbyImageData(_id, args, config),
      },
    },
  };
};

export const createImageFromId = (id: string): IGatsbyImageData | undefined => {
  const asset = createAssetFromId(id);

  return getSimplifiedImageData(asset);
};
export const createImageGatsby = (
  url: string,
  data?: { width?: number; height?: number }
): IGatsbyImageData =>
  ({
    images: {
      fallback: {
        src: url,
      },
    },
    layout: 'constrained',
    ...data,
  } as IGatsbyImageData);
