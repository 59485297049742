import React from 'react';

import { Svg } from 'components/Common/Svg';

import giftSvg from 'svg/gift.svg';
import truckSvg from 'svg/truck.svg';
import dotsGrid from 'svg/dots-grid.svg';
import cancelSvg from 'svg/cancel.svg';
import heartRate from 'svg/heart-rate.svg';
import circlePlus from 'svg/circle-plus.svg';
import dumbbellSvg from 'svg/dumbbell.svg';
import circleGrowth from 'svg/circle-growth.svg';
import supplementSvg from 'svg/supplement.svg';
import calendar30Svg from 'svg/calendar-30.svg';

export type IconType =
  | 'dumbbell'
  | 'supplement'
  | 'gift'
  | 'truck'
  | 'calendar'
  | 'cancel'
  | 'heartRate'
  | 'circlePlus'
  | 'circleGrowth'
  | 'dotsGrid';

export type IconsRecord = Record<
  IconType,
  {
    icon: {
      viewBox?: string | undefined;
      url: string;
    };
    width: string;
    height: string;
  }
>;

export const icons: IconsRecord = {
  dumbbell: {
    icon: dumbbellSvg,
    width: '23.75px',
    height: '16.62px',
  },
  supplement: {
    icon: supplementSvg,
    width: '17.49px',
    height: '19px',
  },
  gift: {
    icon: giftSvg,
    width: '23.22px',
    height: '19px',
  },
  truck: {
    icon: truckSvg,
    width: '25.33px',
    height: '19px',
  },
  calendar: {
    icon: calendar30Svg,
    width: '18.95px',
    height: '19px',
  },
  cancel: {
    icon: cancelSvg,
    width: '18.41px',
    height: '18.41px',
  },
  heartRate: {
    icon: heartRate,
    width: '27px',
    height: '25px',
  },
  circlePlus: {
    icon: circlePlus,
    width: '28px',
    height: '28px',
  },
  circleGrowth: {
    icon: circleGrowth,
    width: '26px',
    height: '26px',
  },
  dotsGrid: {
    icon: dotsGrid,
    width: '25px',
    height: '23px',
  },
};

export const iconTypeList = Object.keys(icons) as IconType[];

export const Icon: React.FC<{ type: IconType }> = ({ type }) => {
  return <Svg {...icons[type]} />;
};
