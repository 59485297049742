import React, { FC } from 'react';
import { ColorProps, HeightProps, WidthProps } from 'styled-utils';
import { ColorBarStyle } from './ColorBar.style';
export const ColorBar: FC<{
  color: ColorProps['color'];
  width?: WidthProps['width'];
  height?: HeightProps['height'];
}> = ({ color, width = '50%', height = '4px' }) => {
  return <ColorBarStyle bg={color} width={width} height={height} />;
};
