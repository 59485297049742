import styled from 'styled-components';
import {
  ScreenAwareColorProps,
  screenAwareColor,
} from 'components/Common/Placement/Placement';

export const FontColorWrapper = styled.div<ScreenAwareColorProps>`
  ${({ theme }) =>
    screenAwareColor(theme.colors.basic800, theme.colors.basic800)};
`;
