import React from 'react';
import { StandardRichTextFragment, SanityStandardImage } from 'graphql-types';
import { BlockProps, SanityRichText } from 'components/Common/SanityRichText';
import { ScreenFontColors } from './ScreenFontColors';
import { createAssetFromId } from 'helpers/image-sanity';
import { GatsbyImage } from 'components/Common/GatsbyImage';

type StandardRichTextProps = {
  fields: StandardRichTextFragment;
  serializerTypes?: Record<string, (props: BlockProps) => JSX.Element | null>;
  maxImageWidth?: number;
};

export const StandardRichText: React.FC<StandardRichTextProps> = ({
  fields,
  serializerTypes,
  maxImageWidth,
}) => {
  const { mobileColor, desktopColor, _rawRichText: richText } = fields;

  const defaultSerializerTypes = {
    standardImage: (props: BlockProps<SanityStandardImage>) => {
      const asset = props.node.image?.asset;
      const assetId = asset?._id;
      if (!asset || !assetId) return null;

      const targetWidth = maxImageWidth || 1280;

      const originalWidth = asset.metadata?.dimensions?.width || 0;
      const width = originalWidth > targetWidth ? targetWidth : originalWidth;

      const image = createAssetFromId(assetId, { width });

      return (
        <GatsbyImage
          image={image.asset?.gatsbyImageData}
          alt={props.node?.alt || ''}
        />
      );
    },
  };

  return (
    <ScreenFontColors mobileColor={mobileColor} desktopColor={desktopColor}>
      <SanityRichText
        blocks={richText}
        serializerTypes={{ ...defaultSerializerTypes, ...serializerTypes }}
      />
    </ScreenFontColors>
  );
};
