import React from 'react';

import { Mark } from './StandardRichText.model';

import { FontColorWrapper } from './StandardRichText.styles';
import { SanityColor } from 'graphql-types';

export const ScreenFontColors: React.FC<{
  desktopColor?: {
    hex?: SanityColor['hex'];
  };
  mobileColor?: {
    hex?: SanityColor['hex'];
  };
}> = ({ desktopColor, mobileColor, ...rest }) => {
  return (
    <FontColorWrapper
      colorMobile={mobileColor?.hex}
      colorDesktop={desktopColor?.hex}
      {...rest}
    />
  );
};

export const ScreenFontColorsSerializer: React.FC<
  Mark<{
    desktopColor?: {
      hex?: SanityColor['hex'];
    };
    mobileColor?: {
      hex?: SanityColor['hex'];
    };
  }>
> = ({ mark, ...rest }) => {
  return (
    <ScreenFontColors
      desktopColor={mark.desktopColor}
      mobileColor={mark.mobileColor}
      {...rest}
    />
  );
};
