import { useEffect, useState } from 'react';
import { device } from 'theme';

export const useMediaQuery = (query: string) => {
  const isBrowser = typeof window !== 'undefined';
  const mediaMatch = isBrowser && window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    if (!mediaMatch) {
      return undefined;
    }
    const handler = (value: MediaQueryListEvent) => setMatches(value.matches);

    // The below check is for older safari browsers - See: https://github.com/mdn/sprints/issues/858
    if (typeof mediaMatch.addEventListener !== 'function') {
      mediaMatch.addListener(handler);
      return () => mediaMatch.removeListener(handler);
    }

    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  }, []);

  return matches;
};

export const useIsMobileOrTablet = () =>
  useMediaQuery(`(max-width: ${device.medium.width})`);

export const useIsMobile = () =>
  useMediaQuery(`(max-width: ${device.small.width})`);

export const useIsTablet = () =>
  useMediaQuery(
    `(min-width: ${device.small.width}) and (max-width: ${device.medium.width})`
  );

export const useIsDesktop = () => !useIsMobileOrTablet();

export const useCustomMaxWidthQuery = (width: string) =>
  useMediaQuery(`(max-width: ${width})`);
