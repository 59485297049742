import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import {
  SanityBlock,
  Maybe,
  SanityButtonFragment,
  SanityIconWithBulletList,
} from 'graphql-types';

import { awaitClick, logEvent } from 'helpers/Amplitude';
import { ScreenFontColors } from 'components/StandardRichText/ScreenFontColors';

import { A, SanityRichTextStyles, TextAlign } from './SanityRichText.styles';
import { Button } from '../Button';
import { icons, IconType } from '../../Icons/Icons';
import {
  SectionItem,
  SectionItemIcon,
  SectionItemIconWrapper,
  SectionItemText,
} from 'components/Product/BundleAddToCartBlock/ProductDescription.styles';
import { ColorBar } from 'components/ColorBar/ColorBar';

export interface BlockProps<T = Record<string, any>> {
  node: T & {
    style: string;
    _key: string;
    type: string;
  };
  children: Array<React.ReactNode>;
}

export type MarkProps<T = Record<string, any>> = Mark<T> & {
  children: Array<React.ReactNode>;
};

export interface SanityRichTextProps {
  blocks?: Maybe<SanityBlock>[];
  className?: string;
  serializerTypes?: Record<string, (props: BlockProps) => JSX.Element | null>;
  markSerializerTypes?: Record<
    string,
    (props: MarkProps) => JSX.Element | null
  >;
}

export type Mark<T = unknown> = {
  mark: T;
  _key: string;
};

export const color: React.FC<Mark<{ hex: string }>> = ({
  children,
  mark,
  _key,
}) => {
  return (
    <span key={_key} style={{ color: mark.hex }}>
      {children}
    </span>
  );
};
export const gradient: React.FC<
  Mark<{
    startColor: { hex: string };
    endColor: { hex: string };
  }>
> = ({ children, mark, _key }) => {
  return (
    <span
      key={_key}
      style={{
        background: `linear-gradient(to right, ${mark.startColor.hex}, ${mark.endColor.hex})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      {children}
    </span>
  );
};

export const sub: React.FC<Mark> = ({ children, _key }) => (
  <sub key={_key}>{children}</sub>
);
export const sup: React.FC<Mark> = ({ children, _key }) => (
  <sup key={_key}>{children}</sup>
);

export const center: React.FC<Mark> = ({ children, _key }) => (
  <TextAlign key={_key} textAlign="center">
    {children}
  </TextAlign>
);

export const centerM: React.FC<Mark> = ({ children, _key }) => (
  <TextAlign key={_key} textAlign={['center', 'left']}>
    {children}
  </TextAlign>
);

export const centerD: React.FC<Mark> = ({ children, _key }) => (
  <TextAlign key={_key} textAlign={['left', 'center']}>
    {children}
  </TextAlign>
);

export const coloredLink: React.FC<
  Mark<{
    color?: {
      hex: string;
    };
    href?: string;
  }>
> = ({ children, mark, _key }) => (
  <A key={_key} color={mark.color?.hex} href={mark.href}>
    {children}
  </A>
);

export const link: React.FC<
  Mark<{
    color?: {
      hex: string;
    };
    href?: string;
  }>
> = ({ children, mark, _key }) => (
  <A key={_key} href={mark.href}>
    {children}
  </A>
);

export const iconWithBulletList: React.FC<{
  node: SanityIconWithBulletList;
}> = ({ node }) => {
  const svgIcon = icons[(node?.icon as IconType) || ''];
  return (
    <SectionItem key={node?._key}>
      {svgIcon && (
        <SectionItemIconWrapper>
          <SectionItemIcon {...svgIcon} />
        </SectionItemIconWrapper>
      )}
      <SectionItemText>{node.title}</SectionItemText>
    </SectionItem>
  );
};

export const button = (props: BlockProps<SanityButtonFragment>) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Button
      href={props.node.ctaLink}
      variant="primary"
      width="300px"
      onClick={awaitClick((e) =>
        logEvent('home-page: Click', { key: e.currentTarget.href })
      )}
    >
      {props.node.text}
    </Button>
  </div>
);

// $95.99 => ['$', '95', '.', '99']
export const getPriceParts = (price = ''): string[] => {
  return /(\D*)(\d+)(\.)?(\d*)/i.exec(price) || [];
};

export const priceVariables: React.FC<
  Mark<{
    text?: string;
    priceAlternateView?: boolean;
  }>
> = ({ mark }) => {
  const { text, priceAlternateView } = mark;

  if (priceAlternateView) {
    const priceParts = getPriceParts(text);
    return (
      <span>
        <sup>{priceParts[1]}</sup>
        {priceParts[2]}
        <sup>{priceParts[4]}</sup>
      </span>
    );
  }

  return <>{text || ''}</>;
};

export const colors = ScreenFontColors;

export const SanityRichText: React.FC<SanityRichTextProps> = ({
  blocks,
  className,
  serializerTypes = {},
  markSerializerTypes = {},
}) => (
  <SanityRichTextStyles>
    <BlockContent
      blocks={blocks}
      serializers={{
        marks: {
          color,
          colors,
          sub,
          sup,
          coloredLink,
          center,
          centerM,
          centerD,
          priceVariables,
          gradient,
          ...markSerializerTypes,
        },
        types: {
          ...serializerTypes,
          button,
          iconWithBulletList,
          colorBar: (props: BlockProps): React.ReactElement | null => (
            <ColorBar
              color={props.node.color.hex}
              width={props.node.width}
              height={props.node.height}
            />
          ),
          block: (props: BlockProps): React.ReactElement | null => {
            if (props.node.style) {
              if (props.node.style.startsWith('heading')) {
                return (
                  <p key={props.node._key} className={props.node.style}>
                    {props.children}
                  </p>
                );
              }
              if (props.node.style === 'display') {
                return (
                  <p key={props.node._key} className="display">
                    {props.children}
                  </p>
                );
              }
              if (props.node.style === 'label') {
                return (
                  <p key={props.node._key} className="label">
                    {props.children}
                  </p>
                );
              }
              if (props.node.style === 'small') {
                return (
                  <p key={props.node._key} className="textSmall">
                    {props.children}
                  </p>
                );
              }
              if (props.node.style === 'xsmall') {
                return (
                  <p key={props.node._key} className="textXSmall">
                    {props.children}
                  </p>
                );
              }
            }
            return BlockContent.defaultSerializers.types.block(props);
          },
        },
      }}
      className={className}
    />
  </SanityRichTextStyles>
);
