import styled from 'styled-components';
import {
  color,
  width,
  height,
  ColorProps,
  WidthProps,
  HeightProps,
} from 'styled-utils';

export const ColorBarStyle = styled.div<ColorProps & WidthProps & HeightProps>`
  ${color};
  ${width};
  ${height};
`;
